import { darkContextualBgPalette } from './darkContextualBgPalette'
import { darkContextualTextPalette } from './darkContextualTextPalette'
import { darkContextualIconPalette } from './darkContextualIconPalette'
import { darkContextualBorderPalette } from './darkContextualBorderPalette'
import { contextualBgPalette } from './contextualBgPalette'
import { contextualTextPalette } from './contextualTextPalette'
import { contextualIconPalette } from './contextualIconPalette'
import { contextualBorderPalette } from './contextualBorderPalette'

export type Variation = {
  name: 'default' | 'light' | 'dark' | (string & {})
  theme: { colors?: Record<string, string>; shadows?: Record<string, string> }
}
export const darkTheme = {
  name: 'dark',
  theme: {
    colors: {
      ...darkContextualBgPalette,
      ...darkContextualTextPalette,
      ...darkContextualIconPalette,
    },
    shadows: darkContextualBorderPalette,
  },
} as const

export const lightTheme = {
  name: 'light',
  theme: {
    colors: {
      ...contextualBgPalette,
      ...contextualTextPalette,
      ...contextualIconPalette,
    },
    shadows: contextualBorderPalette,
  },
} as const
