import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import styled, { ThemeProvider, ThemeContext } from 'styled-components'
import { mergeDeep } from './new/helpers/mergeDeep'
import { darkTheme, Theme, Variation } from './new/theme'
import { defaultPxTheme, defaultTheme } from './new/theme/theme'
import { useDarkModeContext, DarkModeProvider } from './darkModeContext'

interface Props {
  children: ReactNode
  forcePxUnit?: boolean
  ignoreParentTheme?: boolean
  variation?: Variation
}

type VariationWithTheme = Variation & { theme: Theme }

const ThemeProviderWithDarkMode = ({
  children,
  variation,
  ignoreParentTheme,
  forcePxUnit,
}: Props) => {
  const { active: isDarkThemeEnabled } = useDarkModeContext()
  const parentThemeValue = useContext(ThemeContext)
  const parentThemeNameValue = useContext(ThemeNameContext)

  const parsedTheme = useMemo(() => {
    const unitCorrectedDefaultTheme = forcePxUnit ? defaultPxTheme : defaultTheme
    let theme: VariationWithTheme = ignoreParentTheme
      ? { name: 'default', theme: unitCorrectedDefaultTheme }
      : { name: parentThemeNameValue, theme: parentThemeValue ?? unitCorrectedDefaultTheme }

    if (isDarkThemeEnabled) {
      theme = mergeVariationIntoTheme(theme, darkTheme)
    }

    if (variation) {
      theme = mergeVariationIntoTheme(theme, variation)
    }

    return theme
  }, [
    forcePxUnit,
    ignoreParentTheme,
    parentThemeNameValue,
    parentThemeValue,
    isDarkThemeEnabled,
    variation,
  ])

  return (
    <StyledNewColorScheme $colorScheme={parsedTheme.name}>
      <ThemeNameContext.Provider value={parsedTheme.name}>
        <ThemeProvider theme={parsedTheme.theme}>{children}</ThemeProvider>
      </ThemeNameContext.Provider>
    </StyledNewColorScheme>
  )
}

const StyledNewColorScheme = styled.div<{ $colorScheme: Variation['name'] }>`
  display: contents;
  color-scheme: ${({ $colorScheme }) => ($colorScheme === 'default' ? 'light' : $colorScheme)};
`

const mergeVariationIntoTheme = (
  theme: VariationWithTheme,
  variation: Variation
): VariationWithTheme => mergeDeep({}, theme, variation)

const ThemeNameContext = createContext<Variation['name']>('default')

export const useThemeName = () => {
  return useContext(ThemeNameContext)
}

export const ThemeProviderClean = ({ children, variation, ignoreParentTheme }: Props) => {
  const hasParentTheme = useContext(HasParentThemeContext)

  if (hasParentTheme) {
    return (
      <ThemeProviderWithDarkMode variation={variation} ignoreParentTheme={ignoreParentTheme}>
        {children}
      </ThemeProviderWithDarkMode>
    )
  }
  return (
    <HasParentThemeContext.Provider value={true}>
      <DarkModeProvider>
        <ThemeProviderWithDarkMode variation={variation}>{children}</ThemeProviderWithDarkMode>
      </DarkModeProvider>
    </HasParentThemeContext.Provider>
  )
}

// We cant rely on styled-system ThemeProvider value because other libraries can set it in root level
// as happened with storybook actions addon adding its own theme to storybook and messing up with
// this logics
const HasParentThemeContext = createContext(false)
